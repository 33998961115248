<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <span>Filter by</span>
      <a-button type="link" @click="resetFields">Reset Changes</a-button>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="filter-personal-details">
          <div class="filter-personal-details-form">
            <div class="title">Personal Details</div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 33%"
              >
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.phone"
                    class="form-input"
                    placeholder="Enter Phone Number"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%; margin-right: 0"
              >
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.email"
                    class="form-input"
                    placeholder="Enter Email"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.name"
                    class="form-input"
                    placeholder="Enter Name"
                  ></a-input>
                </a-form-item>
              </div>
            </div>

            <div style="display: flex; margin-left: 1rem">
              <div
                class="filter-form-elements left-form-elements"
                style="width: 45%; margin-right: 0"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Enter Group"
                    v-model:value="modelRef.groups"
                    :filter-option="false"
                    :options="groupOptions"
                    @search="getgroupListing"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements"
                style="width: 45%; margin-left: 2rem"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    placeholder="Enter Subgroup"
                    mode="multiple"
                    v-model:value="modelRef.subGroups"
                    :filter-option="false"
                    :options="subGroupOptions"
                    @search="getgroupListing"
                  ></a-select>
                </a-form-item>
              </div>
            </div>

            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 6.5rem"
          />
        </div>
        <div class="vacancy-details">
          <div class="vacancy-details-form">
            <div class="title">Vacancy</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy ID</span>
                  <a-input
                    v-model:value="modelRef.vacancyId"
                    class="form-input"
                    placeholder="Enter"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 65%">
                <a-form-item>
                  <span class="input-label">Vacancy Title</span>
                  <a-input
                    v-model:value="modelRef.title"
                    placeholder="Enter"
                    class="form-input"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy Status</span>
                  <a-input
                    v-model:value="modelRef.status"
                    placeholder="Enter"
                    class="form-input"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div style="display: flex; flex-basis: 57%">
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">Vacancy Type</span>
                    <a-select
                      allowClear
                      class="filter-select"
                      placeholder="Select"
                      label-in-value
                      v-model:value="modelRef.type"
                      :filter-option="false"
                      :options="vacancy_type_options"
                      @search="fetchVacancyTypeData"
                    ></a-select>
                  </a-form-item>
                </div>
                <div
                  class="filter-form-elements"
                  style="flex-basis: 33%; padding: 0px 10px"
                >
                  <a-form-item>
                    <span class="input-label">From Date</span>
                    <a-date-picker
                      v-model:value="modelRef.fromDate"
                      :format="dateFormatList"
                      style="padding-top: 0.65rem; margin-right: 1rem"
                    ></a-date-picker>
                  </a-form-item>
                </div>
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">To Date</span>
                    <a-date-picker
                      v-model:value="modelRef.toDate"
                      :format="dateFormatList"
                      style="padding-top: 0.65rem; margin-right: 1rem"
                      placeholder="To Date"
                    ></a-date-picker>
                  </a-form-item>
                </div>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 53.5%"
              >
                <a-form-item>
                  <span class="input-label">Lead CIC</span>
                  <a-select
                    allowClear
                    class="filter-select"
                    placeholder="Select"
                    :options="lead_cic_options"
                    v-model:value="modelRef.leadCic"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 37.5%">
                <a-form-item>
                  <span class="input-label">Originated By</span>
                  <a-select
                    allowClear
                    class="filter-select"
                    placeholder="Select"
                    :options="lead_cic_options"
                    v-model:value="modelRef.originatedBy"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 20.5rem"
          />
        </div>
        <div class="commpany-taxonomy-details">
          <div class="commpany-taxonomy-details-form">
            <div class="title">Company Taxonomy</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Sector"
                    label-in-value
                    v-model:value="modelRef.sectors"
                    :filter-option="false"
                    :options="sector_tags_options"
                    @search="fetchSectorData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Industry"
                    label-in-value
                    v-model:value="modelRef.industries"
                    :filter-option="false"
                    :options="industry_tags_options"
                    @search="fetchIndustryData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 95.5%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Vertical"
                    label-in-value
                    v-model:value="modelRef.verticals"
                    :filter-option="false"
                    :options="verticals_tags_options"
                    @search="fetchVerticalData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem; width: 100%"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    v-model:value="modelRef.practiceAreas"
                    mode="multiple"
                    placeholder="Select Practice Area"
                    label-in-value
                    :filter-option="false"
                    :options="practice_area_tags_options"
                    @search="fetchPracticeAreaData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    v-model:value="modelRef.subPracticeAreas"
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Sub Practice Area"
                    label-in-value
                    :filter-option="false"
                    :options="sub_practice_area_tags_options"
                    @search="fetchSubPracticeAreaData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="
                  flex-basis: 31%;
                  margin-left: 1rem;
                  width: 100%;
                  margin-right: 5px;
                "
                class="filter-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Function"
                    label-in-value
                    v-model:value="modelRef.functions"
                    :filter-option="false"
                    :options="function_tags_options"
                    @search="fetchFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements"
                style="flex-basis: 31%; margin-right: 5px"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Sub Function"
                    label-in-value
                    v-model:value="modelRef.subFunctions"
                    :filter-option="false"
                    :options="sub_function_tags_options"
                    @search="fetchSubFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 31%">
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="multiple"
                    placeholder="Select Sub Sub Function"
                    label-in-value
                    v-model:value="modelRef.subSubFunctions"
                    :filter-option="false"
                    :options="sub_sub_function_tags_options"
                    @search="fetchSubSubFunctionData;"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 31%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Company Type</span>
                  <a-select
                    class="filter-select"
                    placeholder="Select"
                    mode="multiple"
                    label-in-value
                    v-model:value="modelRef.companyTypes"
                    :filter-option="false"
                    :options="company_types_tags_options"
                    @search="fetchCompanyTypeData"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 35.5rem"
          />
        </div>
        <div class="lorem-ipsum-section">
          <div class="lorem-ipsum-section-form">
            <div class="title">Lorem Ipsum</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Degree</span>
                  <a-select
                    class="filter-select"
                    :options="education_degrees_options"
                    v-model:value="modelRef.degree"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 61%">
                <a-form-item>
                  <span class="input-label">Institute</span>
                  <a-select
                    class="filter-select"
                    :options="university_options"
                    v-model:value="modelRef.institutes"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Resume Type</span>
                  <a-select
                    class="filter-select"
                    :options="resume_options"
                    v-model:value="modelRef.resumeType"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">City</span>
                  <a-select
                    class="filter-select"
                    :options="city_options"
                    v-model:value="modelRef.city"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <span class="input-label">Designation</span>
                  <a-input
                    v-model:value="modelRef.designation"
                    class="form-input"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 14.5rem"
          />
        </div>
        <div class="candidate-footer">
          <a-button
            type="link"
            style="
              color: rgb(76, 81, 85);
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            key="back"
            @click="closeFilter"
            >Cancel</a-button
          >
          <a-button
            key="submit"
            style="
              background: RGB(228, 129, 106);
              color: #ffffff;
              border-radius: 15px;
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            @click="filterModalOk"
            >Apply Filter</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { Form, message } from "ant-design-vue";
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";
import { useStore } from "vuex";

const useForm = Form.useForm;

export default defineComponent({
  setup(props, context) {
    let vacancy_type_options = ref([]);
    let lead_cic_options = ref([]);
    let sector_tags_options = ref([]);
    let industry_tags_options = ref([]);
    let verticals_tags_options = ref([]);
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let function_tags_options = ref([]);
    let sub_function_tags_options = ref([]);
    let sub_sub_function_tags_options = ref([]);
    let company_types_tags_options = ref([]);
    let education_degrees_options = ref([]);
    let university_options = ref([]);
    let resume_options = ref([]);
    let city_options = ref([]);
    let groupOptions = ref([]);
    let subGroupOptions = ref([]);
    let pageCount = 0;
    const store = useStore();
    const dateFormatList = ["DD/MM/YYYY"];

    let modelRef = reactive({
      phone: "",
      email: "",
      name: "",
      vacancyId: "",
      title: "",
      status: "",
      type: ref([]),
      fromDate: "",
      toDate: "",
      leadCic: ref([]),
      originatedBy: ref([]),
      sectors: ref([]),
      industries: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subPracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      companyTypes: ref([]),
      degree: ref([]),
      institutes: ref([]),
      resumeType: ref([]),
      city: ref([]),
      designation: "",
      groups: computed(
        () => store.getters["candidateStore/getCandidateGroupId"]
      ).value,
      subGroups: computed(
        () => store.getters["candidateStore/getSubGroupIdOfCandidate"]
      ).value,
    });
    let payload = {
      phone: "",
      email: "",
      name: "",
      vacancy: {
        vacancyId: "",
        title: "",
        status: "",
        type: ref([]),
        fromDate: "",
        toDate: "",
      },
      leadCic: ref([]),
      originatedBy: ref([]),
      sectors: ref([]),
      industries: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subPracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      companyTypes: ref([]),
      degree: ref([]),
      institutes: ref([]),
      resumeType: ref([]),
      city: ref([]),
      designation: "",
      vacancyFilter: "",
      groups: computed(
        () => store.getters["candidateStore/getCandidateGroupId"]
      ).value,
      subGroups: computed(
        () => store.getters["candidateStore/getSubGroupIdOfCandidate"]
      ).value,
    };
    const { resetFields } = useForm(modelRef);

    const fetchVacancyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["VACANCY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          vacancy_type_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          verticals_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        mode: "AUTOCOMPLETE",
        types: ["SUB_PRACTICE_AREA"],
      };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["COMPANY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          company_types_tags_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const populateSubGroupOptions = (value) => {
      console.log("value :>> ", value);
    };

    const getGroupSubgroupData = () => {
      modelRef.groups = computed(
        () => store.getters["candidateStore/getCandidateGroupId"]
      ).value
        ? computed(() => store.getters["candidateStore/getCandidateGroupId"])
            .value
        : ref([]);

      modelRef.subGroups = computed(
        () => store.getters["candidateStore/getSubGroupIdOfCandidate"]
      ).value
        ? computed(
            () => store.getters["candidateStore/getSubGroupIdOfCandidate"]
          ).value
        : ref([]);
    };

    onMounted(() => {
      fetchVacancyTypeData();
      fetchVerticalData();
      fetchPracticeAreaData();
      fetchSubPracticeAreaData();
      fetchFunctionData();
      fetchSubFunctionData();
      fetchSubSubFunctionData();
      fetchSectorData();
      fetchIndustryData();
      fetchCompanyTypeData();
      getgroupListing();
      getGroupSubgroupData();

      let user_listing_payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          lead_cic_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.eduDegreesListing({ types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          education_degrees_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.eduInstituteListing({ types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          university_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.resumeListing({ types: ["RESUME"] })
        .then((response) => {
          const { data } = response;
          resume_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.cityListing()
        .then((response) => {
          const { data } = response;
          city_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });

    async function getgroupListing(value) {
      let payload = {
        q: "",
        pageNo: pageCount,
      };

      if (value) {
        payload = { q: value, pageNo: pageCount, mode: "AUTOCOMPLETE" };
      }

      subGroupOptions.value.splice(0);

      await Services.getCandidateGroup(payload)
        .then((response) => {
          const { data } = response;
          groupOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );

          data.data.map((group) => {
            group.subGroups.map((x) => {
              subGroupOptions.value.push({
                value: x.id,
                label: x.name,
              });
            });
          });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    function closeFilter() {
      context.emit("closeFilter", false);
      modelRef.groups = ref([]);
      modelRef.subGroups = ref([]);

      getGroupSubgroupData();
    }

    function filterModalOk() {
      console.log("modelRef :>> ", modelRef);
      let tempObject = Utils.deepCopyObject(payload);
      modelRef.phone
        ? (tempObject["phone"] = modelRef.phone)
        : delete tempObject.phone;
      modelRef.email
        ? (tempObject["email"] = modelRef.email)
        : delete tempObject.email;
      modelRef.name
        ? (tempObject["name"] = modelRef.name)
        : delete tempObject.name;
      modelRef.vacancyId
        ? (tempObject.vacancy.vacancyId = modelRef.vacancyId)
        : delete tempObject.vacancy.vacancyId;
      modelRef.title
        ? (tempObject.vacancy.title = modelRef.title)
        : delete tempObject.vacancy.title;
      modelRef.status
        ? (tempObject.vacancy.status = modelRef.status)
        : delete tempObject.vacancy.status;
      modelRef.fromDate
        ? (tempObject.vacancy.fromDate = Utils.displayYearFirst(
            modelRef.fromDate
          ))
        : delete tempObject.vacancy.fromDate;
      modelRef.toDate
        ? (tempObject.vacancy.toDate = Utils.displayYearFirst(modelRef.toDate))
        : delete tempObject.vacancy.toDate;
      modelRef.type.length > 0
        ? (tempObject.vacancy.type = modelRef.type.map((x) => x.value))
        : delete tempObject.vacancy.type;
      modelRef.leadCic.length > 0
        ? (tempObject.leadCic = modelRef.leadCic)
        : delete tempObject.leadCic;
      modelRef.originatedBy.length > 0
        ? (tempObject.originatedBy = modelRef.originatedBy)
        : delete tempObject.originatedBy;
      modelRef.sectors.length > 0
        ? (tempObject.sectors = modelRef.sectors.map((x) => x.value))
        : delete tempObject.sectors;
      modelRef.industries.length > 0
        ? (tempObject.industries = modelRef.industries.map((x) => x.value))
        : delete tempObject.industries;
      modelRef.verticals.length > 0
        ? (tempObject.verticals = modelRef.verticals.map((x) => x.value))
        : delete tempObject.verticals;
      modelRef.practiceAreas.length > 0
        ? (tempObject.practiceAreas = modelRef.practiceAreas.map(
            (x) => x.value
          ))
        : delete tempObject.practiceAreas;
      modelRef.subPracticeAreas.length > 0
        ? (tempObject.subPracticeAreas = modelRef.subPracticeAreas.map(
            (x) => x.value
          ))
        : delete tempObject.subPracticeAreas;
      modelRef.functions.length > 0
        ? (tempObject.functions = modelRef.functions.map((x) => x.value))
        : delete tempObject.functions;
      modelRef.subFunctions.length > 0
        ? (tempObject.subFunctions = modelRef.subFunctions.map((x) => x.value))
        : delete tempObject.subFunctions;
      modelRef.subSubFunctions.length > 0
        ? (tempObject.subSubFunctions = modelRef.subSubFunctions.map(
            (x) => x.value
          ))
        : delete tempObject.subSubFunctions;
      modelRef.companyTypes.length > 0
        ? (tempObject.companyTypes = modelRef.companyTypes.map((x) => x.value))
        : delete tempObject.companyTypes;
      modelRef.degree.length > 0
        ? (tempObject.degree = modelRef.degree)
        : delete tempObject.degree;
      modelRef.institutes.length > 0
        ? (tempObject.institutes = modelRef.institutes)
        : delete tempObject.institutes;
      modelRef.resumeType.length > 0
        ? (tempObject.resumeType = modelRef.resumeType)
        : delete tempObject.resumeType;
      modelRef.city.length > 0
        ? (tempObject.city = modelRef.city)
        : delete tempObject.city;
      modelRef.designation
        ? (tempObject["designation"] = modelRef.designation)
        : delete tempObject.designation;
      modelRef.groups
        ? (tempObject.groups = modelRef.groups)
        : delete tempObject.groups;
      // modelRef.groups.length > 0
      //   ? (tempObject.groups = modelRef.groups.map((x) => x.value))
      //   : delete tempObject.groups;
      modelRef.subGroups
        ? (tempObject.subGroups = modelRef.subGroups)
        : delete tempObject.subGroups;
      if (Object.keys(tempObject.vacancy).length > 0) {
        tempObject["vacancyFilter"] = JSON.stringify(tempObject.vacancy);
      } else {
        delete tempObject.vacancy;
        delete tempObject.vacancyFilter;
      }
      context.emit("filterModalOk", tempObject, false);
      message.success("Filter applied successfully");
    }

    function resetFilter() {
      store.dispatch("candidateStore/resetState");

      modelRef.phone = "";
      modelRef.email = "";
      modelRef.name = "";
      modelRef.groups = ref([]);
      modelRef.subGroups = ref([]);
      modelRef.vacancyId = "";
      modelRef.title = "";
      modelRef.status = "";
      modelRef.type = ref([]);
      modelRef.fromDate = "";
      modelRef.toDate = "";
      modelRef.leadCic = ref([]);
      modelRef.originatedBy = ref([]);
      modelRef.sectors = ref([]);
      modelRef.industries = ref([]);
      modelRef.verticals = ref([]);
      modelRef.practiceAreas = ref([]);
      modelRef.subPracticeAreas = ref([]);
      modelRef.functions = ref([]);
      modelRef.subFunctions = ref([]);
      modelRef.subSubFunctions = ref([]);
      modelRef.companyTypes = ref([]);
      modelRef.degree = ref([]);
      modelRef.institutes = ref([]);
      modelRef.resumeType = ref([]);
      modelRef.city = ref([]);
      modelRef.designation = "";
    }

    return {
      modelRef,
      resetFields,
      closeFilter,
      filterModalOk,
      resetFilter,
      // All select elements value refs
      vacancyTypeValue: ref([]),
      leadCICValue: ref([]),
      originatebyValue: ref([]),
      sectorValue: ref([]),
      industryValue: ref([]),
      verticalsValue: ref([]),
      practiceAreaValue: ref([]),
      subPracticeAreaValue: ref([]),
      functionValue: ref([]),
      subFunctionValue: ref([]),
      subSubFunctionValue: ref([]),
      companyTypeValue: ref([]),
      degreeValue: ref([]),
      universityValue: ref([]),
      resumeValue: ref([]),
      cityValue: ref([]),
      pageCount,

      // All select items options
      vacancy_type_options,
      lead_cic_options,
      sector_tags_options,
      industry_tags_options,
      verticals_tags_options,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      function_tags_options,
      sub_function_tags_options,
      sub_sub_function_tags_options,
      company_types_tags_options,
      education_degrees_options,
      university_options,
      resume_options,
      city_options,
      groupOptions,
      subGroupOptions,
      dateFormatList,

      // Select Api call Funtions
      fetchVacancyTypeData,
      fetchVerticalData,
      fetchPracticeAreaData,
      fetchSubPracticeAreaData,
      fetchFunctionData,
      fetchSubFunctionData,
      fetchSubSubFunctionData,
      fetchSectorData,
      fetchIndustryData,
      fetchCompanyTypeData,
      getgroupListing,
      populateSubGroupOptions,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/candidateFilter.scss";
</style>
